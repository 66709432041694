import * as React from "react";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLoader } from "@egds/react-core/loader";

export const DealsLoader = (props: any) => (
  <EGDSLayoutFlex justifyContent="center">
    <EGDSSpacing margin={{ blockstart: "four" }}>
      <EGDSLayoutFlexItem>
        <div data-testid="deals-loader">
          <EGDSLoader />
        </div>
      </EGDSLayoutFlexItem>
    </EGDSSpacing>
  </EGDSLayoutFlex>
);

export default DealsLoader;
