import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { DealsViewsProps } from "../typings";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";

export const DealsCarousel = (props: DealsViewsProps) => {
  const { campaign, renderMerchCampaignCard, focusElement } = props;

  const { formatText } = useLocalization();

  return (
    <EGDSSpacing margin={{ blockstart: "three" }}>
      <IsomorphicCarousel
        itemsVisible={{
          lg: 3,
          md: 2,
          sm: 1,
        }}
        buttonText={{
          nextButton: formatText("carousel.item.next"),
          prevButton: formatText("carousel.item.prev"),
        }}
        pageBy={1}
        peek
      >
        {campaign.offers!.map((offer, cardIndex) => {
          return (
            <EGDSLayoutFlex key={`deals-carousel-${offer.name}-${cardIndex}`} blockSize="full_size" direction="column">
              <EGDSLayoutFlexItem minWidth="full_width">
                <div ref={focusElement} tabIndex={-1} data-testid="deals-carousel">
                  {renderMerchCampaignCard(campaign, offer, cardIndex)}
                </div>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          );
        })}
      </IsomorphicCarousel>
    </EGDSSpacing>
  );
};

export default DealsCarousel;
