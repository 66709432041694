import * as React from "react";
import { DealsDebugProps } from "components/flexComponents/Deals/typings";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

export const DealsDebugInfo = (props: DealsDebugProps) => {
  const { errorMessage, data, campaignId } = props;
  const responseId = data?.campaignRecommendations?.responseId;

  return (
    <EGDSSpacing padding={{ block: "two" }}>
      <div data-testid="deals-debug-info">
        <div data-testid="deals-debug-info-campaign-id">
          <EGDSText inline="strong">Campaign ID:</EGDSText> {campaignId ?? "Not available."}
        </div>
        {errorMessage && (
          <div data-testid="deals-debug-info-error-message">
            <EGDSText inline="strong">Error Message:</EGDSText> {errorMessage}
          </div>
        )}
        {data?.campaignRecommendations?.campaigns[0]?.offers && (
          <div data-testid="deals-debug-offer-count">
            <EGDSText inline="strong">Offer/Destination count:</EGDSText>{" "}
            {data?.campaignRecommendations?.campaigns[0]?.offers?.length}
          </div>
        )}
        {responseId && (
          <div data-testid="deals-debug-info-response-id">
            <EGDSText inline="strong">Response Id:</EGDSText> {responseId}
          </div>
        )}
        {data && (
          <>
            <div data-testid="deals-debug-response">
              <EGDSText inline="strong">Response:</EGDSText>
            </div>
            <div>
              <textarea rows={20} style={{ width: "90%" }}>
                {JSON.stringify(data, null, 2)}
              </textarea>
            </div>
          </>
        )}
      </div>
    </EGDSSpacing>
  );
};
