import * as React from "react";
import { EGDSCardPlaceholder } from "@egds/react-core/cards";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { GridPlaceholderProps } from "../typings";
import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const GridPlaceholder = (gridPlaceholderProps: GridPlaceholderProps) => {
  const keyHelper = new ItemKeyHelper(`${gridPlaceholderProps.templateComponentId}-placeholder-Grid`);

  return (
    <EGDSSpacing margin={{ blockend: "three", blockstart: "four" }}>
      <div className="Deals">
        <EGDSSpacing margin={{ inlinestart: "three" }}>
          <EGDSCardPlaceholder className="placeholder-header" />
        </EGDSSpacing>
        <EGDSSpacing margin={{ inlinestart: "three", blockstart: "two" }}>
          <EGDSCardPlaceholder className="placeholder-subheader" />
        </EGDSSpacing>
        <EGDSSpacing margin={{ inline: "three", blockstart: "three" }}>
          <EGDSLayoutGrid columns={{ medium: 2, extra_large: 3, small: 1 }} space="three">
            {[...Array(12)].map((val, index) => (
              <div key={keyHelper.next()}>
                <EGDSCardPlaceholder className="placeholder-card" />
              </div>
            ))}
          </EGDSLayoutGrid>
        </EGDSSpacing>
      </div>
    </EGDSSpacing>
  );
};
