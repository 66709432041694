import * as React from "react";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSImage, EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSEmptyState } from "@egds/react-core/empty-state";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";

export const DealsErrorFallback = () => {
  const { formatText } = useLocalization();
  const fallbackSrc =
    "https://a.travel-assets.com/travel-assets-manager/d4c0c838-61ca-41ee-bb38-3c3a550cbbf7/nodeals_160x160-2x.png";

  return (
    <EGDSLayoutFlex direction="column">
      <EGDSLayoutFlexItem>
        <EGDSSpacing margin={{ block: "three" }}>
          <div data-testid="deals-error-fallback" className="Deals">
            <div className="deals-error-fallback-image">
              <EGDSFigure ratio={EGDSFigureAspectRatioType.R4_1} className="fallback-image" imageFit="cover">
                <EGDSImage className="deals-fallback-image" alt="" src={fallbackSrc} placeholderImage={false} />
              </EGDSFigure>
            </div>

            <div>
              <EGDSEmptyState>
                <EGDSHeading tag="h2" size={5}>
                  {formatText("deals.fallbackView.title")}
                </EGDSHeading>
                <EGDSText>{formatText("deals.fallbackView.cta")}</EGDSText>
              </EGDSEmptyState>
            </div>
          </div>
        </EGDSSpacing>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );
};
